import * as React from "react";
import { graphql } from "gatsby";
import PageBody from "@/components/PageBody";
import Paragraph from "@/components/Paragraph";
import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";
import { tutorApply } from "../config/ContactForm.data";

export default function TutorApplyPage() {
  const seo = createSeoData({
    title: "Tutor Application",
    description:
      "Do you love teaching language? Lingua Fonica is continually looking for talented foreign language tutors to teach our engaged students. Submit an application here.",
  });

  const hero = {
    heroTitleLine1: "Teach a language",
    heroTitleLine2: "anywhere & anytime",
    heroContent: "Teach from the comfort of your home.",
  };

  return (
    <Layout seo={seo} hero={hero} leadFormFields={tutorApply}>
      <PageBody limitMaxWidth>
        <Paragraph>
          Thank you for your interest in working with Lingua Fonica! We are
          looking for creative tutors who are comfortable teaching remote. We
          have built our own custom platform optimized for language learning,
          and the feedback from our tutors and students helps us to continually
          improve it. Lingua Fonica tutors should center the needs of our
          students and work with them to develop individualized learning plans
          to meet their goals.
        </Paragraph>
        <Paragraph>
          Once you fill out the application, a team member will contact you to
          request your CV/resume, contact information for your references, and
          to schedule an interview. For the interview, please prepare a
          10-minute mini lesson so we can get a sense of your teaching style and
          you can get a sense of our platform.
        </Paragraph>
        <Paragraph>We look forward to getting to know you!</Paragraph>
      </PageBody>
    </Layout>
  );
}

// hero section data query
export const query = graphql`
  {
    contentfulHomePage {
      heroTitle
      heroContent {
        heroContent
      }
      metaDescription
      heroTitleLine2
    }
  }
`;
